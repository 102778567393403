<template>
  <div id="app" :class="'custom-theme-' + mainColor">
    <router-view />
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { get } from 'lodash';
  import { customColor } from './util';
  export default {
    name: 'App',
    computed: {
      ...mapState('user', {
        appConfig: (state) => state.appConfig,
      }),
      mainColor() {
        const color = get(this.appConfig, "extraData.health.styles.mainColor", null);
        if (color) {
          customColor(color);
        }
        return color;
      }
    }
  }
</script>
