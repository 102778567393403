<template>
  <div class="result-wrapper">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-card
        v-for="item in couponList"
        :key="item.id"
        :num="item.couponCount"
        :price="item.price"
        :desc="item.couponName"
        :title="item.activityName"
        :thumb="item.cover"
      />
    </van-list>
  </div>
</template>
<script>
import { queryCouponPage } from "@/api/activity";
export default {
  data() {
    return {
      loading: false,
      finished: false,
      couponList: [],
      page: {
        current: 1,
        size: 10
      }
    };
  },
  methods: {
    onLoad() {
      this.loading = true
      queryCouponPage(this.page, this.$route.query.phone).then(res => {
        this.loading = false
        if (res.code === 0) {
          if (res.data.records.length === 0) {
            this.finished = true
            return
          }
          this.page.current++
          res.data.records.map(item => {
            if (!item.price.includes('.')) {
              item.price+= '.0'
            }
          })
          this.couponList = this.couponList.concat(res.data.records)
          if (this.couponList.length === res.data.total) {
            this.finished = true
          }
        }
      })
    },
  }
};
</script>
<style scoped lang="less">
.van-card {
  border-bottom: 1px solid #e9e9e9;
}
</style>
