<template>
  <div class="search-wrapper">
    <div class="search-bg">
      <img :src="bgImg" alt="">
    </div>
    <div class="search-content">
      <div class="search-title">
        请输入查询内容
      </div>
      <div class="search-form">
        <van-form @submit="onSubmit" label-width="45px" :show-error="false">
          <van-field
            v-model="queryForm.name"
            label="姓名"
            placeholder="请输入姓名"
            :rules="[{ required: true, message: '请输入姓名' }]"
          />
          <van-field
            v-model="queryForm.mobilePhone"
            label="手机号"
            placeholder="请输入手机号"
            :rules="[{ required: true, message: '请输入手机号' }, { pattern: /^1[3-9]\d{9}$/, message: '手机号码格式错误！' }]"
          >
            <template #button>
              <van-button size="mini" :disabled="isSmsSend" type="primary" @click.prevent="sendAuthCode">{{ sendBtnText }}</van-button>
            </template>
          </van-field>
          <van-field
            v-model="queryForm.verificationCode"
            label="验证码"
            placeholder="请输入验证码"
            :rules="[{ required: true, message: '请输入验证码' }]"
          />
          <div class="search-btn">
            <van-button color="#db0011" block type="info" native-type="submit">立即查询</van-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>
<script>
import { queryVerificationCode, queryBbcResult } from "@/api/activity";
export default {
  data() {
    return {
      bgImg: require('@/assets/images/bbc-banner.png'),
      queryForm: {
        name: '',
        mobilePhone: '',
        verificationCode: '',
      },
      isSmsSend: false,
      sendBtnText: "验证码获取",
      counter: 120,
      timer: null
    };
  },
  methods: {
    sendAuthCode() {
      if (!this.queryForm.mobilePhone) {
        this.$notify("请输入手机号");
        return false;
      }
      // 判断手机号是否符合要求
      if (this.queryForm.mobilePhone.length !== 11) {
        this.$notify("请输入11位手机号");
        return false;
      }
      // 调用接口，发送短信验证码
      this.isSmsSend = true;
      // 开始倒计时，60s之后才能再次点击
      this.countDown();
      this.verificationCode();
    },
    countDown() {
      this.timer = setInterval(() => {
        this.sendBtnText = `(${this.counter}秒)后重新发送`;
        this.counter--;
        if (this.counter < 0) {
          this.reset();
        }
      }, 1000);
    },
    reset() {
      this.isSmsSend = false;
      this.sendBtnText = "验证码获取";
      if (this.timer) {
        clearInterval(this.timer);
        this.counter = 120;
        this.timer = null;
      }
    },
    verificationCode() {
      queryVerificationCode(this.queryForm.mobilePhone).then(
        ({ code }) => {
          if (code === 0) {
            this.$notify("已发送验证码");
          } else {
            this.$notify("验证码发送失败");
          }
        }
      );
    },
    onSubmit() {
      queryBbcResult(this.queryForm).then(res => {
        if (res.code === 0) {
          this.$router.push({
            path: '/emp/bbcResult',
            query: {
              info: JSON.stringify(this.queryForm)
            }
          })
        }
      })
    }
  }
};
</script>
<style scoped lang="less">
.flex-block {
  display: flex;
  align-items: center;
}
.flex-center {
  justify-content: center;
}
.flex-column {
  flex-flow: column;
}
.search-wrapper {
  .search-bg {
    width: 100%;
    height: 200px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .search-content {
    position: relative;
    width: 100%;
    margin: 10px auto 0 auto;
    background: #fff;
    box-shadow: 1px 1px 5px #ccc;
    z-index: 9;
    .search-title {
      padding: 10px 15px;
      font-size: 16px;
      font-weight: bold;
      text-align: left;
    }
    .search-form {
      padding-bottom: 10px;
    }
    .search-btn {
      margin: 16px;
      width: calc(100% - 32px);
    }
  }
}
</style>
