<template>
  <div class="Content">
    <div>
      <img :src="activity?activity.posterUrl:'' " width="100%" />
    </div>
    <van-form @submit="onSubmit" style="padding: 0 0.6rem">
      <div>
        <p class="styleFont">称谓</p>
        <van-radio-group
          class="styleMargin"
          v-model="user.gender"
          direction="horizontal"
        >
          <van-radio class="Top" name="1">先生</van-radio>
          <van-radio class="Top" name="2">女士</van-radio>
          <!-- <van-radio class="Top" name="3">未知</van-radio> -->
        </van-radio-group>
        <p class="styleFont">姓名</p>
        <van-field
          class="styleMargin"
          v-model="user.name"
          placeholder="请输入姓名"
          required
          :rules="[{ required: true }]"
        />
        <p class="styleFont">联系方式</p>
        <van-field
          class="styleMargin"
          v-model="user.mobilePhoneNumber"
          :maxlength="11"
          placeholder="输入手机号"
          required
          :rules="[
            { required: true },
            { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' },
          ]"
        />
        <p class="styleFont">短信验证码</p>
        <van-field
          class="styleMargin"
          v-model="user.verificationCode"
          center
          clearable
          required
          :maxlength="6"
          :rules="[{ required: true }]"
          placeholder="请输入短信验证码"
        >
          <van-button
            @click.prevent="sendSmsCode"
            slot="button"
            size="small"
            :disabled="isSmsSend"
            type="primary"
            >{{ sendBtnText }}</van-button
          >
          <!-- <van-button @@click="sendSmsCode" slot="button" size="small" type="primary" :disabled="isSmsSend">{{ sendBtnText }}</van-button> -->
        </van-field>
        <p class="styleFont">城市</p>
        <van-cell
          required
          class="styleMargin"
          is-link
          :title="locationTitle"
          @click="showLocation = true"
        />
        <van-action-sheet
          v-model="showLocation"
          :actions="columns"
          @select="onSelect"
        />
      </div>
      <!-- $t('emp.authorizationInfo') -->
      <div style="font-size:14px" v-html="`${$t('emp.authorizationInfo')}`">
        
      </div>
      <div style="margin: 0.5rem 0; text-align: center">
        <!-- <van-button round block native-type="submit" style="width: 4rem;margin: 0 auto;" type="danger">报名参加</van-button> -->
        <van-button native-type="submit" class="button" type="danger"
          >报名参加</van-button
        >
      </div>
    </van-form>
    <div>
      <img :src="activity?activity.posterDownUrl:'' " width="100%" />
    </div>
    <van-dialog
      v-model="showSliderDialog"
      title="请拖动图片到指定位置"
      :show-cancel-button="false"
      :show-confirm-button="false"
      theme="round-button"
    >
      <slide-verify ref="slideblock" slider-text="向右滑动" @fail="onFail" @success="onSuccess" @refresh="onRefresh" :l="42" :r="10" :w="300" :h="150" :imgs="slideImgs" />
    </van-dialog>
    <!-- <van-overlay :show="true" @click="show = false"><van-loading style="top:50%" size="24px" color="#fff" vertical>提交中...请勿关闭页面</van-loading></van-overlay> -->
  </div>
</template>
    <script>
import { empRegister, checkUrl, sendVerificationCode,getActivityById,getCityByActivityId,registerByLogin ,checkSignature,godzillaCallback,updateYuanQiStatus} from "@/api/activity";
import { Dialog,Notify } from "vant";
import { getSessionData } from "@/util/sessionData";
import { get } from "lodash";
export default {
  data() {
    return {
      activity:null,
      showLocation: false,
      columns: [
        // { value: 310000, name: "上海" },
        // { value: 440100, name: "广州" },
        // { value: 440300, name: "深圳" },
        // { value: 330100, name: "杭州" },
        // { value: 440600, name: "佛山" },
        // { value: 110000, name: "北京" },
        // { value: 610000, name: "西安" },
      ],
      locationTitle: "请选择居住地",
      // 是否已经发送了验证码
      isSmsSend: false,
      sendBtnText: "点击发送验证码",
      timer: null,
      counter: 120,
      title: "",
      failContent: "",
      show: false,
      user: {
        mobilePhoneNumber: "",
        gender: "1",
        location: "",
        locationCode: "",
        actId: "",
        verificationCode: "",
        name: "",
        channel:"",
        channelExtraParam:""
      },
      phoneNumReset: "",
      showSliderDialog:false,
      verified:false,
      slideImgs:[
        require('@/assets/images/1.jpg'),
        require('@/assets/images/2.jpg'),
        require('@/assets/images/3.jpg')
      ],
      channel:undefined,
      yuanqisenlin:{
        openId:"",
        channel:"HSBC",
        validateResult:false
      }
    };
  },
  async mounted() {
    const channel = this.getUrlCode().channel
    if(channel && channel === 'yuanqi'){
      this.channel = channel;
      await this.getParamsAndCheckSignature();
    }
    this.queryCitys(this.getUrlCode().actId)
    const personInfo = getSessionData("personInfo");
    this.user.gender = get(personInfo, "sex", "1");
    this.user.name = get(personInfo, "name", "");
    this.user.mobilePhoneNumber = get(personInfo, "mobile", "");
    this.getParamsAndCheckUrl();
    this.queryActivity(this.getUrlCode().actId)
  },
  methods: {
    onFail(){
      this.$refs.slideblock.reset();
    },
    onRefresh(){
      this.$refs.slideblock.reset();
    },
    onSuccess(){
      this.showSliderDialog = false
      if(this.channel === 'yuanqi'){
        if(!this.yuanqisenlin.validateResult){
          this.$notify("签名校验未通过，无法报名");
          return;
        }
        const queryObject = this.getUrlCode()
        this.user.channel = "yuanqi"
        const extraParam = {
          channel:this.yuanqisenlin.channel,
          openId:this.yuanqisenlin.openId,
          url:`?app_id=${queryObject.app_id}&nonce=${queryObject.nonce}&sign=${queryObject.sign}&signature=${queryObject.signature}&timestamp=${queryObject.timestamp}`
        }
        this.user.channelExtraParam = JSON.stringify(extraParam);
        Notify({ type: 'warning', message: '提交中，请勿关闭页面' });
      }
      empRegister(this.user).then(({ code, data, msg }) => {
        if (code === 0) {
          if(this.channel === 'yuanqi'){
            this.pushResultToYuanQiSenLin(code,msg,'')
          }
          else{
            this.$router.push({
            path: '/emp/result',
            query: {
              channel:this.channel,
              actId: this.getUrlCode().actId,
              signData: data ? JSON.stringify(data) : ''
            }
          })
          }
        } else {
          //前置校验不满足的话 先不跳转页面
          if(code === -1){
            this.$notify(msg);
          }else{
            this.$router.push({
            path: '/emp/result',
            query: {
              channel:this.channel,
              actId: this.getUrlCode().actId,
              errMsg: JSON.stringify(msg)
            }
          })
          }
        }
      });
    },
    onSelect(item) {
      this.showLocation = false;
      this.locationTitle = item.name;
      this.user.location = item.name;
      this.user.locationCode = item.value;
    },
    getUrlCode() {
      // 截取url中的code方法
      const url = location.search;
      // this.winUrl = url
      const theRequest = new Object();
      if (url.indexOf("?") != -1) {
        const str = url.substr(1);
        const strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
    getParamsAndCheckUrl() {
      const dataApplets = {
        actId: "",
        secret: "",
      };
      dataApplets.actId = this.getUrlCode().actId;
      dataApplets.secret = this.getUrlCode().secret;
      checkUrl(dataApplets).then(({ code, data }) => {
        console.log(code);
        if (data.code === 0) {
          this.user.actId = dataApplets.actId;
        } else {
          this.$notify(data.msg);
        }
      });
    },
    getParamsAndCheckSignature() {
      const queryObject = this.getUrlCode()
      this.yuanqisenlin.openId = queryObject.openId
      const params = {
        appId: queryObject.app_id,
        nonce: queryObject.nonce,
        sign:queryObject.sign,
        timestamp:queryObject.timestamp,
        signature:queryObject.signature
      };
      checkSignature(params).then((res) => {
        if (res.data) {
          this.yuanqisenlin.validateResult = true;
        }else{
          this.$notify("签名校验失败");
        }
      });
    },
    pushResultToYuanQiSenLin(code,orderId,msg) {
      const params = {
        code: code,
        channel: this.yuanqisenlin.channel,
        openId:this.yuanqisenlin.openId,
        orderId:orderId,
        message:msg
      };
      const queryObject = this.getUrlCode()
      const url = `?app_id=${queryObject.app_id}&nonce=${queryObject.nonce}&sign=${queryObject.sign}&signature=${queryObject.signature}&timestamp=${queryObject.timestamp}`
      godzillaCallback(params,url).then((res) => {
        console.log(res);
        if (res.error == 0) {
          updateYuanQiStatus(orderId).then(r=>{
            if(!r.data){
              this.$notify("推送成功，更新失败");
            }
            this.$router.push({
            path: '/emp/result',
            query: {
              channel:this.channel,
              actId: this.getUrlCode().actId,
              signData: ''
            }
          })
          });
        }else{
          this.$router.push({
            path: '/emp/result',
            query: {
              channel:this.channel,
              actId: this.getUrlCode().actId,
              errMsg: JSON.stringify("留资报名成功，但推送结果失败")
            }
          })
        }
      });
    },
    /**
     * 重置倒计时
     */
    reset() {
      this.isSmsSend = false;
      this.sendBtnText = "点击发送验证码";
      if (this.timer) {
        clearInterval(this.timer);
        this.counter = 120;
        this.timer = null;
      }
    },
    /**
     * 倒计时
     */
    countDown() {
      this.timer = setInterval(() => {
        this.sendBtnText = `(${this.counter}秒)后重新发送`;
        this.counter--;
        if (this.counter < 0) {
          this.reset();
        }
      }, 1000);
    },
    /**
     * 发送验证码
     */
    sendSmsCode() {
      // // 判断手机号是否已经输入
      if (!this.user.mobilePhoneNumber) {
        this.$notify("请输入手机号");
        return false;
      }
      // 判断手机号是否符合要求
      if (this.user.mobilePhoneNumber.length !== 11) {
        this.$notify("请输入11位手机号");
        return false;
      }
      // 调用接口，发送短信验证码
      this.isSmsSend = true;
      // 开始倒计时，60s之后才能再次点击
      this.countDown();
      this.verificationCode();
    },
    // 当手机号变化时，重置发送按钮
    onPhoneChange() {
      if (this.user.mobilePhoneNumber != this.phoneNumReset) {
        this.reset();
      }
    },
    onphoneNumReset() {
      this.phoneNumReset = this.user.mobilePhoneNumber;
    },
    onSubmit() {
      if (this.user.actId.length == 0) {
        this.$notify("活动不能为空");
        return;
      }
      if (this.user.location.length == 0) {
        this.$notify("请选择居住地");
        return;
      }
      if (this.user.locationCode.length == 0) {
        this.$notify("请选择居住地");
        return;
      }
      //不需要登录的话
      if(this.activity.needLogin === 2){
        this.showSliderDialog = true
        this.onRefresh()
      }else{
        registerByLogin(this.user).then(({ code, data, msg }) => {
        if (code === 0) {
          this.$router.push({
            path: '/emp/result',
            query: {
              actId: this.getUrlCode().actId,
              signData: data ? JSON.stringify(data) : ''
            }
          })
        } else {
          if(code === -1){
            this.$notify(msg);
          }else{
          this.$router.push({
            path: '/emp/result',
            query: {
              actId: this.getUrlCode().actId,
              errMsg: JSON.stringify(msg)
            }
          })
          }
        }
      });
      }
    },
    verificationCode() {
      sendVerificationCode(this.user.mobilePhoneNumber).then(
        ({ code, data }) => {
          console.log("data", data);
          if (code === 0) {
            this.$notify("已发送验证码");
          } else {
            this.$notify("验证码发送失败");
          }
        }
      );
    },
    queryActivity(eventId){
      getActivityById(eventId).then(({ code, data }) => {
        if (code === 0) {
          this.activity = data;
          document.title = this.activity.name
        }
      });
    },
    queryCitys(eventId){
      getCityByActivityId(eventId).then(({ code, data }) => {
        if (code === 0) {
          let citys = []
          data.forEach(item=>{
            citys.push({
              value:item.cityCode,
              name:item.cityName
            })
          })
          this.columns = citys
        }
      });
    },
  },
};
</script>
    <style scoped lang="less">

/deep/ .van-dialog__content{
  display: flex;
  justify-content: center;
  padding: 20px
}
.Content {
  .ml(12px);
  margin: 0px;
  .Top {
    font-size: 14px;
    margin-left: 10px;
    color: #646566;
  }
  .styleMargin {
    margin: 10px 0px;
  }
  .dialogMargin {
    margin: 20px 20px;
  }
  .imgSrc1 {
    margin: 0px 0px 0px 20px;
    vertical-align: middle;
    width: 23px;
    height: 20px;
  }
  .imgSrc {
    vertical-align: middle;
    width: 23px;
    height: 20px;
  }
  .styleFont {
    font-size: 14px;
  }
  .hintFont {
    padding: 0 0.5rem 0.3rem;
    font-weight: 600;
    font-size: 14px;
  }
  .button {
    width: 200px;
    margin: 0 auto;
    color: #ffffff;
    font-size: 18px;
  }
  .styleSpan {
    margin: 20px;
  }
  .styleSpanMistake {
    width: 100%;
    text-align: center;
  }
}


</style>

<style>
.self_dialog .van-dialog__footer .van-dialog__confirm{
  background-color: #fff;
}
</style>
