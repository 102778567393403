<template>
  <div class="result-wrapper">
    <div class="flex-block query-title">
      <div class="flex-label">查询信息：</div>
      <div class="flex-content">{{ queryForm.mobilePhone }}</div>
    </div>
    <div class="result-tab">
      <van-tabs v-model="active" @click="changeTab">
        <van-tab title="全部"></van-tab>
        <van-tab title="成功"></van-tab>
        <van-tab title="失败"></van-tab>
      </van-tabs>
      <div class="coupon-img" @click="toCouponCenter">点击进入卡券中心</div>
      <div class="result-coupon-block" v-for="item in queryResult" :key="item.activityId">
        <div class="flex-block coupon-block__item">
          <div class="flex-label coupon-block__detail-label">活动名称</div>
          <div class="flex-content">{{ item.activityName }}</div>
        </div>
        <div class="flex-block coupon-block__item">
          <div class="flex-label coupon-block__detail-label">报名时间</div>
          <div class="flex-content">{{ item.signUpTime }}</div>
        </div>
        <div class="flex-block coupon-block__item">
          <div class="flex-label coupon-block__detail-label">报名状态</div>
          <div class="flex-content">{{ item.signUpStatus === '1' ? '报名成功' : '报名失败' }}</div>
        </div>
        <div class="flex-block coupon-block__item">
          <div class="flex-label coupon-block__detail-label">是否领取卡券</div>
          <div class="flex-content">{{ item.couponId ? '是' : '否' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { queryBbcResult } from "@/api/activity";
export default {
  data() {
    return {
      active: 0,
      queryForm: null,
      queryResult: []
    };
  },
  created() {
    this.queryForm = JSON.parse(this.$route.query.info)
    this.init()
  },
  methods: {
    init(status) {
      let tempObj = JSON.stringify(this.queryForm)
      let queryParams = JSON.parse(tempObj)
      if (status) {
        queryParams.signUpStatus = status + ''
      }
      queryBbcResult(queryParams).then(res => {
        if (res.code !== 0) {
          this.$router.push('/emp/search')
        } else {
          this.queryResult = res.data
        }
      })
    },
    changeTab() {
      if (this.active === 0) {
        this.init()
      } else {
        this.init(this.active)
      }
    },
    toCouponCenter() {
      this.$router.push('/emp/couponCenter')
      this.$router.push({
        path: '/emp/couponCenter',
        query: {
          phone: this.queryForm.mobilePhone
        }
      })
    },
  }
};
</script>
<style scoped lang="less">
.flex-block {
  display: flex;
  align-items: center;
}
.flex-center {
  justify-content: center;
}
.flex-column {
  flex-flow: column;
}
.flex-label {
  width: 70px;
}
.result-wrapper {
  background: #fff;
  font-size: 14px;
  .query-title {
    padding: 10px;
    border-bottom: 1px solid #f3f3f3;
  }
  .result-tab {
    .coupon-img {
      margin: 10px auto;
      width: 100%;
      height: 60px;
      line-height: 60px;
      font-size: 24px;
      text-align: center;
      background: #fce6e8;
      color: #e85c67;
    }
    .result-coupon-block {
      width: 98%;
      margin: 10px auto;
      padding: 10px;
      border: 1px solid transparent;
      border-radius: 5px;
      box-shadow: 1px 1px 5px #ccc;

      .flex-label {
        width: 100px;
      }

      .coupon-block__item {
        padding: 10px 0;
        border-bottom: 1px solid #f3f3f3;
      }

      .coupon-block__detail-label {
        padding: 5px;
      }
    }
  }
}
</style>
