<template>
  <div class="content">
    <Nav
      title="讲座"
      :fixed="true"
      rightText="我的记录"
      @click-right="toMyRecord"
    >
      <template #left>&nbsp;</template>
      <template #right>&nbsp;</template>
    </Nav>
    <van-count-down
      v-if="isLive && time.days < 3000"
      class="count-dowm flex-block font14"
      :time="time"
    >
      <template #default="timeData">
        <van-icon class="count-icon" size="20" name="info" />
        <span>距离直播开始：</span>
        <span class="block">{{ timeData.days }}</span>
        <span class="colon">天</span>
        <span class="block">{{ timeData.hours }}</span>
        <span class="colon">时</span>
        <span class="block">{{ timeData.minutes }}</span>
        <span class="colon">分</span>
        <span class="block">{{ timeData.seconds }}</span>
        <span class="colon">秒</span>
      </template>
    </van-count-down>
    <div class="lecture-detail" :class="{ isLive: isLive && time.days < 3000 }">
      <div class="lecture-video player-container">
        <!-- <img :src="lectureDetail.coverUrl" alt="" /> -->
        <van-image fit="cover" width="100%" height="100%" :src="lectureDetail.coverUrl" />
        <div
          class="play-icon"
          v-if="lectureDetail.lineType === 0"
          @click="beforePlay"
        >
          <div class="triangle"></div>
        </div>
      </div>
      <div class="flex-block lecture-title">
        <div class="line30 font16 font400">
          {{ lectureDetail.name }}
          <!-- <span class="font12 price">{{
            lectureDetail.costType === 0 ? "免费" : "付费"
          }}</span> -->
        </div>
      </div>
      <div class="flex-block light-grey location-block">
        <van-icon name="location-o" />
        <div class="font13">{{ lectureDetail.location }}</div>
      </div>
      <div class="flex-block light-grey lecture-judge">
        <!-- <van-rate
          v-model="lectureDetail.scoreNumber"
          readonly
          :size="18"
          allow-half
          color="#ffd21e"
          void-icon="star"
          void-color="#FDEFD5"
        />
        <div class="rate-num font14">{{ lectureDetail.score }}</div> -->
        <!-- div class="flex-block light-grey">
          <van-icon class="margRig4" name="eye-o" />
          <div class="font13 margRig16">{{ lectureDetail.viewCount }}</div>
        </div -->
        <!-- <div class="flex-block light-grey">
          <van-icon v-if="like" class="margRig4" color="#DB0011" name="like" @click="cancelLike" />
          <van-icon v-else class="margRig4" name="like-o" @click="giveLike" />
          <div class="font13 margRig16">{{ lectureDetail.likeCount }}</div>
        </div>
        <div class="flex-block light-grey" @click="showComment">
          <van-icon class="margRig4" name="comment-o" />
          <div class="font13">{{ lectureDetail.commentCount }}</div>
        </div> -->
      </div>
    </div>
    <div class="description" v-if="lectureDetail.playType !== 0">
      <div class="desc-title font16 font400">讲座时间</div>
      <div class="flex-block arrange">
        <div class="font16 margRig16 light-grey">{{ lectureDetail.city }}</div>
        <div class="arrange-detail">
          <div class="flex-block font13 light-grey">
            <van-icon class="margRig4" name="clock-o" />
            <div class="font13">
              讲座时间：
              <span v-if="lectureDetail.startTime && (new Date(lectureDetail.startTime).getYear() - new Date().getYear() > 10)">待定</span>
              <span v-else>
              {{ lectureDetail.startTime && lectureDetail.startTime.endsWith(':00') ? lectureDetail.startTime.slice(0, lectureDetail.startTime.length - 3) : lectureDetail.startTime }}
                            <span v-if="lectureDetail.endTime">
                                              &nbsp;-&nbsp;{{ lectureDetail.endTime.endsWith(':00') ? lectureDetail.endTime.slice(lectureDetail.endTime.length - 8, lectureDetail.endTime.length - 3) : lectureDetail.endTime }}
                                            </span>
              </span>
            </div>
          </div>
          <div
            class="flex-block font13 light-grey arrange-location"
            v-if="lectureDetail.lineType === 1"
          >
            <van-icon class="margRig4" name="location-o" />
            <div class="font13">{{ lectureDetail.address }}</div>
          </div>
          <div
            class="flex-block font13 light-grey"
            v-if="lectureDetail.lineType === 1"
          >
            <van-icon class="margRig4" name="user-o" />
            <div class="font13">
              剩余名额：<span class="left-number">{{
                lectureDetail.maxNumber - lectureDetail.alreadyNumber
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="description">
      <div class="desc-title font16 font400">讲座简介</div>
      <p class="light-grey font14" v-html="lectureDetail.description"></p>
    </div>
    <div class="description">
      <div class="desc-title font16 font400">讲师简介</div>
      <p
        class="light-grey font14"
        v-html="lectureDetail.lecturerDescription"
      ></p>
    </div>
    <div class="appoint-btn" v-if="btnTxt">
      <van-button
        type="primary"
        size="large"
        @click.stop="appointNow"
        :color="btnColor"
      >
        {{ btnTxt }}
      </van-button>
    </div>
    <van-dialog
      v-model="showPopup"
      :title="commonTitle"
      show-cancel-button
      confirmButtonText="同意"
      :cancelButtonText="cancelTxt"
      @confirm="handleConfirm"
      @cancel="showPopup = false"
    >
      <div class="popup-content" v-if="!lectureDetail.pointAmount">
        <p class="font13 font400 light-grey">
          由于本功能需要使用体检相关隐私数据作为分析依据，相关条款如下由于本功能需要使用体检相关隐私数据作为分析依据，相关条款如下；
        </p>
        <p class="popup-tips font13 font500">
          提示：如果以上服务不感兴趣，可以选择“跳过”，直接进入福利方案选择界面
        </p>
      </div>
      <div class="popup-content" v-else>
        <p class="font13 font400 light-grey">
          {{
            lectureDetail.lineType === 0 ? "观看此视频" : "报名此讲座"
          }}需扣除{{ lectureDetail.pointAmount }}点积分，是否确认扣除？
        </p>
      </div>
    </van-dialog>
    <!-- <van-popup v-model="showCommentPopup" round position="bottom" :style="{ height: '60%' }">
      <div class="flex-block comment-title font400">
        <span>{{ lectureDetail.commentCount }}条评论</span>
        <van-icon name="cross" @click="showCommentPopup = false" />
      </div>
      <ul class="comment-list">
        <li v-for="(item, index) in commentList" :key="index">
          <div class="flex-block user-info-line">
            <div class="flex-block">
              <img class="user-avatar" :src="item.avatar" alt="">
              <span class="font16 font500">{{ item.name }}</span>
            </div>
            <span class="light-grey font13 font400">{{ item.createTime }}</span>
          </div>
          <p class="comment-content light-grey font14 font400">{{ item.content }}</p>
        </li>
      </ul>
      <div class="comment-btn" v-if="lectureDetail.lineType === 1 || (lectureDetail.lineType === 0 && lectureDetail.playType === 1)">
        <van-field
          v-model="sendComment"
          center
          clearable
          placeholder="点击评论"
        >
          <template #button>
            <van-button size="small" type="primary" color="#DB0011" @click="commitComment">提交</van-button>
          </template>
        </van-field>
      </div>
    </van-popup> -->
  </div>
</template>

<script>
import Nav from "@/components/VNavbar";
import { getSessionData } from "@/util/sessionData";
import {
  getLectureDetail,
  // getlikeStatus,
  // createlike,
  // disLike,
  // getLectureComment,
  // addComment,
  viewVideo,
  queryIsPayed,
  pointPay,
  createSignup,
  querySignStatus,
} from "@/api/lecture";
const personInfo = getSessionData("personInfo");
export default {
  components: {
    Nav,
  },
  data() {
    return {
      commonTitle: "汇丰私人财富规划服务",
      cancelTxt: "跳过",
      time: null,
      active: 0,
      lectureDetail: {},
      like: false,
      btnTxt: "",
      btnColor: "#DB0011",
      showPopup: false,
      showCommentPopup: false,
      commentList: [],
      sendComment: "",
      isLive: false,
      lectureStatus: "",
      hasPayed: false,
      isRequest: false,
      isPaying: false,
    };
  },
  created() {
    this.init();
    // this.queryLikeStatus()
  },
  methods: {
    init() {
      getLectureDetail(this.$route.query.id).then((res) => {
        let curTime = new Date(res.currentDate.replace(/-/g, "/")).getTime();
        let startTime = res.data.startTime
          ? new Date(res.data.startTime.replace(/-/g, "/")).getTime()
          : res.data.startTime;
        let endTime = res.data.endTime
          ? new Date(res.data.endTime.replace(/-/g, "/")).getTime()
          : res.data.endTime;
        this.lectureDetail = res.data;
        this.lectureDetail.scoreNumber = Number(this.lectureDetail.score);
        this.lectureDetail.location =
          this.lectureDetail.lineType === 0
            ? `线上/${this.lectureDetail.playType === 0 ? "录播" : "直播"}`
            : `线下/${this.lectureDetail.address}`;
        if (
          this.lectureDetail.lineType === 0 &&
          this.lectureDetail.playType === 1
        ) {
          this.time = startTime - curTime <= 0 ? 0 : startTime - curTime;
        }
        this.isLive =
          this.lectureDetail.lineType === 0 &&
          this.lectureDetail.playType === 1 &&
          this.time > 0;
        this.lectureStatus = startTime
          ? this.handleStatusBtn(curTime, startTime, endTime)
          : "";
        this.handleBtnTxt(res.data);
        if (res.data.costType === 1) {
          queryIsPayed(this.$route.query.id).then((res) => {
            this.hasPayed = res.data;
          });
        }
      });
    },
    // queryLikeStatus() {
    //   getlikeStatus(this.$route.query.lectureDetailId).then(res => {
    //     this.like = res.data
    //   })
    // },
    handleBtnTxt(data) {
      // playType 0录播；1直播
      // lineType 0线上；1 线下
      // costType 0免费；1收费
      if (this.lectureStatus === "未开始") {
        if (data.lineType === 1) {
          let params = {
            lectureId: this.$route.query.id,
            lectureDetailId: this.$route.query.lectureDetailId,
            userName: personInfo.name,
            phone: personInfo.mobile,
            idCard: personInfo.certificateNo,
          };
          querySignStatus(params).then((res) => {
            if (res.data) {
              this.btnTxt = "已报名";
              this.btnColor = "#00847F";
            } else {
              this.btnTxt = "立即预约";
            }
          });
        } else {
          if (data.playType === 1) {
            this.btnTxt = "未开始";
            this.btnColor = "#D7D8D6";
          }
        }
      } else if (this.lectureStatus === "进行中") {
        if (data.playType === 1) {
          this.btnTxt = "前往";
        }
      } else {
        this.btnTxt = this.lectureStatus;
        this.btnColor = "#D7D8D6";
      }
    },
    handleStatusBtn(cur, start, end) {
      if (this.lectureDetail.playType === 0) {
        return "";
      }
      if (cur < start) {
        return "未开始";
      } else if (start <= cur && cur <= end) {
        return "进行中";
      } else {
        return "已结束";
      }
    },
    // giveLike() {
    //   let params = {
    //     lectureId: this.$route.query.id,
    //     lectureDetailId: this.$route.query.lectureDetailId
    //   }
    //   createlike(params).then(res => {
    //     if (res.data) {
    //       this.$notify({ type: "success", message: "点赞成功" })
    //       this.lectureDetail.likeCount++
    //       this.queryLikeStatus()
    //     } else {
    //       this.$notify({ type: "danger", message: "点赞失败" })
    //     }
    //   })
    // },
    // cancelLike() {
    //   disLike(this.$route.query.lectureDetailId).then(res => {
    //     if (res.data) {
    //       this.$notify({ type: "success", message: "取消点赞成功" })
    //       this.lectureDetail.likeCount--
    //       this.queryLikeStatus()
    //     } else {
    //       this.$notify({ type: "danger", message: "取消点赞失败" })
    //     }
    //   })
    // },
    // getCommentList() {
    //   let params = {
    //     lectureId: this.$route.query.id,
    //     lectureDetailId: this.$route.query.lectureDetailId
    //   }
    //   getLectureComment(params).then(res => {
    //     this.commentList = res.data.records
    //     this.lectureDetail.commentCount = res.data.total
    //   })
    // },
    // async showComment() {
    //   this.showCommentPopup = true
    //   await this.getCommentList()
    // },
    // commitComment() {
    //   let params = {
    //     name: personInfo.name,
    //     avatar: 'https://flex-static-api.prod.app.hsbcfts.com.cn/cxagroup-flex/1518153755273486338.png',
    //     lectureId: this.$route.query.id,
    //     lectureDetailId: this.$route.query.lectureDetailId,
    //     content: this.sendComment
    //   }
    //   addComment(params).then(res => {
    //     if (res.data) {
    //       this.$notify({ type: "success", message: "评论成功" })
    //       this.getCommentList()
    //     } else {
    //       this.$notify({ type: "danger", message: "评论失败" })
    //     }
    //   })
    //   this.sendComment = ''
    //   this.showCommentPopup = false
    // },
    signActivity() {
      let params = {
        lectureId: this.$route.query.id,
        lectureDetailId: this.$route.query.lectureDetailId,
        userName: personInfo.name,
        phone: personInfo.mobile,
        idCard: personInfo.certificateNo,
        idType: personInfo.certificateType,
        tenantId: personInfo.tenantId,
        tenantName: personInfo.tenantName,
      };
      createSignup(params).then((res) => {
        if (res.data) {
          this.$notify({ type: "success", message: "报名成功" });
          this.btnTxt = "已报名";
          this.btnColor = "#00847F";
        } else {
          this.$notify({ type: "danger", message: "报名失败" });
        }
      });
    },
    appointNow() {
      if (this.btnTxt === "立即预约") {
        if (this.lectureDetail.costType === 0) {
          this.signActivity();
        } else {
          if (!this.hasPayed) {
            if (this.isPaying) {
              return;
            }
            this.commonTitle = "提示";
            this.cancelTxt = "取消";
            this.showPopup = true;
          } else {
            this.signActivity();
          }
        }
      } else if (this.btnTxt === "前往") {
        this.beforePlay();
        // let params = {
        //   lectureId: this.$route.query.id,
        //   lectureDetailId: this.$route.query.lectureDetailId,
        // }
        // viewVideo(params).then(res => {
        //   if (res) {
        //     window.location.href = this.lectureDetail.lectureUrl
        //   }
        // })
      }
    },
    beforePlay() {
      let params = {
        lectureId: this.$route.query.id,
        lectureDetailId: this.$route.query.lectureDetailId,
        userName: personInfo.name,
        phone: personInfo.mobile,
        idCard: personInfo.certificateNo,
        idType: personInfo.certificateType,
        tenantId: personInfo.tenantId,
        tenantName: personInfo.tenantName,
      };
      if (this.lectureDetail.costType === 1) {
        if (this.btnTxt === "已结束") {
          this.$notify({ type: "danger", message: "直播已结束" });
          return;
        }
        if (!this.hasPayed) {
          if (this.isPaying) {
            return;
          }
          this.commonTitle = "提示";
          this.cancelTxt = "取消";
          this.showPopup = true;
        } else {
          if (this.isRequest) {
            return;
          }
          this.isRequest = true;
          viewVideo(params).then((res) => {
            this.isRequest = false;
            if (res) {
              window.location.href = this.lectureDetail.lectureUrl;
            }
          });
        }
      } else {
        if (this.isRequest) {
          return;
        }
        this.isRequest = true;
        viewVideo(params).then((res) => {
          this.isRequest = false;
          if (res) {
            window.location.href = this.lectureDetail.lectureUrl;
          }
        });
      }
    },
    handleConfirm() {
      this.showPopup = false;
      if (this.isPaying) {
        return;
      }
      console.log("222------", this.isPaying);
      if (this.lectureDetail.pointAmount) {
        let params = {
          lectureId: this.$route.query.id,
          lectureDetailId: this.$route.query.lectureDetailId,
          payType: 0,
          remark: "",
        };
        if (this.hasPayed) {
          return;
        }
        this.isPaying = true;
        pointPay(params).then((res) => {
          if (res) {
            this.isPaying = false;
            this.hasPayed = true;
            this.$notify({ type: "success", message: "支付成功" });
            if (this.btnTxt === "立即预约") {
              this.signActivity();
              return;
            }
            let params = {
              lectureId: this.$route.query.id,
              lectureDetailId: this.$route.query.lectureDetailId,
              userName: personInfo.name,
              phone: personInfo.mobile,
              idCard: personInfo.certificateNo,
              idType: personInfo.certificateType,
              tenantId: personInfo.tenantId,
              tenantName: personInfo.tenantName,
            };
            if (this.isRequest) {
              return;
            }
            this.isRequest = true;
            viewVideo(params).then((res) => {
              this.isRequest = false;
              if (res) {
                window.location.href = this.lectureDetail.lectureUrl;
              }
            });
          } else {
            this.$notify({ type: "danger", message: "支付失败" });
            this.isPaying = false;
            this.hasPayed = false;
          }
        });
      } else {
        this.$router.push("/lecture");
      }
    },
    toMyRecord() {
      this.$router.push("/lecture/record");
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .van-nav-bar--fixed {
  z-index: 999 !important;
}
/deep/ .van-nav-bar__text {
  color: #353535;
}
/deep/ .van-dialog {
  border-radius: 0;
}
/deep/ .van-dialog__header {
  padding: 22px 18px 0 18px;
  text-align: left;
}
/deep/ .van-dialog__cancel {
  height: 40px;
  border: 1px solid #353535;
  font-size: 15px;
}
/deep/ .van-dialog__confirm {
  height: 40px;
  border: 1px solid #db0011;
  background: #db0011;
  color: #ffffff;
  font-size: 15px;
}
.content {
  background: #f7f7f7;

  .count-dowm {
    position: fixed;
    top: 46px;
    left: 0;
    width: 100%;
    height: 44px;
    background: #305a85;
    color: #ffffff;
    z-index: 99;

    .count-icon {
      margin: 0 12px;
    }
  }

  .lecture-detail {
    width: calc(100% - 32px);
    margin: 16px;
    background: #ffffff;

    .vm-player {
      min-height: 0px;
    }
  }

  .isLive {
    margin-top: 60px;
  }

  .description {
    width: calc(100% - 32px);
    margin: 16px;
    padding: 16px 16px 16px 6px;
    background: #ffffff;

    .desc-title {
      padding-left: 6px;
      border-left: 4px solid #db0011;
    }

    p {
      padding: 12px 0 0 10px;
    }

    .arrange {
      padding: 12px 0 0 10px;
    }

    .arrange-detail {
      flex-flow: column;
    }

    .arrange-location {
      margin: 16px 0;
    }

    .left-number {
      color: #db0011;
    }
  }

  .flex-block {
    display: flex;
    align-items: center;
  }

  .line30 {
    line-height: 30px;
  }

  .font16 {
    font-size: 16px;
  }

  .font14 {
    font-size: 14px;
  }

  .font13 {
    font-size: 13px;
  }

  .font12 {
    font-size: 12px;
  }

  .font500 {
    font-weight: 500;
  }

  .font400 {
    font-weight: 400;
  }

  .light-grey {
    color: #767676;
  }

  .lecture-video {
    position: relative;
    width: 100%;
    height: 146px;
    img {
      width: 100%;
      height: 100%;
    }
    .play-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 48px;
      height: 48px;
      transform: translate(-50%, -50%);
      background: rgba(0, 0, 0, 0.4);
      .triangle {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 0;
        height: 0;
        transform: translate(-25%, -50%);
        border: 15px solid transparent;
        border-left: 18px solid #ffffff;
      }
    }
  }

  .lecture-title {
    margin: 14px 14px 10px 14px;
    align-items: center;
  }
  .price {
    padding: 2px 8px;
    margin-left: 15px;
    background: #ededed;
    color: #333333;
  }
  .location-block {
    margin-left: 12px;
    padding-bottom: 8px;
  }

  .lecture-judge {
    padding: 5px 0 17px 16px;
  }

  .rate-num {
    margin: 0 32px 0 4px;
  }

  .margRig4 {
    margin-right: 4px;
  }

  .margRig16 {
    margin-right: 16px;
  }

  .appoint-btn {
    margin: 0 15px 20px 15px;
  }

  .popup-content {
    padding: 8px 18px 24px 18px;
  }

  .popup-tips {
    margin-top: 16px;
    color: #58595b;
  }

  .comment-title {
    position: fixed;
    width: 100%;
    top: calc(40% - 1px);
    z-index: 9;
    background: #ffffff;
    border-radius: 24px 24px 0 0;
    padding: 16px;
    color: #333333;
    font-size: 19px;
    justify-content: space-between;
  }

  .comment-list {
    padding: 62px 16px 58px 16px;

    .user-info-line {
      justify-content: space-between;
    }

    .user-avatar {
      width: 36px;
      height: 36px;
      border-radius: 18px;
      margin-right: 8px;
    }

    .comment-content {
      padding: 8px 0 24px 44px;
    }
  }

  .comment-btn {
    position: fixed;
    bottom: 0;
    width: 100%;

    /deep/ .van-field__body {
      .van-field__control {
        border: 1px solid #d8d5d5;
        height: 35px;
        padding-left: 5px;
      }
    }
  }
}
</style>
<style>
.van-nav-bar__right {display: none;}
</style>
