<template>
  <div class="result flex-block flex-column">
    <div class="appoint-result flex-block flex-center flex-column">
      <van-icon v-if="appointStatus === 1" name="checked" />
      <van-icon v-else name="clear" />
      <div class="appoint-result__status">{{ resultText }}</div>
      <div class="appoint-result__tips" v-if="appointStatus === 1">汇丰私人财富规划师将尽快与您致电联系</div>
      <div class="appoint-result__tips" v-if="appointStatus === 2">{{ errorMsg }}</div>
    </div>
    <div v-if="appointStatus === 1 && hasCoupon" class="award-wrapper">
      <div class="award">恭喜您获得{{ awardInfo.couponName }}一张</div>
      <div class="award-detail">
        <div class="flex-block" @click="copy('cardNo')">
          <div class="award-label">卡号：</div>
          <div class="award-content copy-item">{{ awardInfo.writeOffCode }}</div>
          <van-icon name="records" />
        </div>
        <div class="flex-block" @click="copy('cardPsd')">
          <div class="award-label">卡密：</div>
          <div class="award-content copy-item">{{ awardInfo.writeOffPassword }}</div>
          <van-icon name="records" />
        </div>
        <div class="flex-block" v-if="awardInfo.writeOffContent" @click="copy('cardLink')">
          <div class="award-label">链接：</div>
          <div class="award-content copy-item">{{ awardInfo.writeOffContent }}</div>
          <van-icon name="records" />
        </div>
        <div class="flex-block" v-if="awardInfo.remark">
          <div class="award-label">备注：</div>
          <div class="award-content">{{ awardInfo.remark }}</div>
        </div>
      </div>
    </div>
    <div class="jump-image" @click="toDetailLink">
      <img :src="jumpImg" alt="">
    </div>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
import { queryBbcConfig } from "@/api/activity";
export default {
  data() {
    return {
      appointStatus: 1,
      resultText: '',
      hasCoupon: false,
      awardInfo: {
        couponName: '',
        writeOffCode: '',
        writeOffPassword: '',
        writeOffContent: '',
        remark: '',
      },
      jumpImg: '',
      bbcConfig: null,
      errorMsg: ''
    };
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const routeQuery = this.$route.query
      if (routeQuery.errMsg) {
        this.appointStatus = 2
        this.resultText = '预约失败'
        this.errorMsg = routeQuery.errMsg
        await this.getBbcConfig(routeQuery.actId, 'fail')
      } else {
        this.appointStatus = 1
        this.resultText = '预约成功'
        await this.getBbcConfig(routeQuery.actId, 'success')
      }
    },
    getBbcConfig(val, status) {
      queryBbcConfig(val).then(res => {
        if (res.code === 0) {
          this.bbcConfig = res.data
          this.jumpImg = res.data.couponImage
          const seconds = this.bbcConfig.countDown ? this.bbcConfig.countDown * 1000 : 3000
          if (this.bbcConfig.jumpMode === '1') {
            let timer = setTimeout(() => {
              this.handleJump()
            }, seconds)
          }
          if (status === 'fail' || !this.$route.query.signData)  return
          const routeInfo = JSON.parse(this.$route.query.signData)
          this.awardInfo.couponName = res.data.couponName
          this.awardInfo.writeOffCode = routeInfo.writeOffCode
          this.awardInfo.writeOffPassword = routeInfo.writeOffPassword
          this.awardInfo.writeOffContent = routeInfo.writeOffContent
          this.awardInfo.remark = routeInfo.remark
          this.hasCoupon = res.data.couponMode === '1'
        }
      })
    },
    copy(type) {
      let oInput = document.createElement("input");
      if (type === 'cardNo') {
        if (this.awardInfo && this.awardInfo.writeOffCode && this.awardInfo.writeOffCode.length > 0) {
          oInput.value = this.awardInfo.writeOffCode;
        }
      } else if (type === 'cardPsd') {
        if(this.awardInfo && this.awardInfo.writeOffPassword && this.awardInfo.writeOffPassword.length > 0){
          oInput.value = this.awardInfo.writeOffPassword;
        } else {
          oInput.value = '';
        }
      } else {
        if(this.awardInfo && this.awardInfo.writeOffContent && this.awardInfo.writeOffContent.length > 0){
          oInput.value = this.awardInfo.writeOffContent;
        } else {
          oInput.value = '';
        }
      }
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      this.$notify("复制成功");
      oInput.remove();
    },
    handleJump() {
      if (this.bbcConfig.jumpType === '1') {
        //跳转类型：链接
        window.location.href = this.bbcConfig.jumpAddress
      } else if (this.bbcConfig.jumpType === '2') {
        //跳转类型：小程序
        wx.miniProgram.navigateBack({});
        // window.location.href = `weixin://dl/miniprogram?id=${this.bbcConfig.miniAppid}&page=${this.bbcConfig.miniPath}`
      }
    },
    toDetailLink() {
      if (this.bbcConfig.jumpMode === '0') {
        this.handleJump()
      }
    }
  }
};
</script>
<style scoped lang="less">
.flex-block {
  display: flex;
  align-items: center;
}
.flex-center {
  justify-content: center;
}
.flex-column {
  flex-flow: column;
}
.result {
  padding-top: 40px;
  .appoint-result {
    width: 100%;
    margin-bottom: 15px;
    .van-icon-checked {
      margin-bottom: 20px;
      font-size: 50px;
      color: #00847f;
    }
    .van-icon-clear {
      margin-bottom: 20px;
      font-size: 50px;
      color: #a8000b;
    }
    .appoint-result__status {
      margin-bottom: 15px;
      font-size: 15px;
      font-weight: bold;
    }

    .appoint-result__tips {
      color: #000;
      font-size: 15px;
      font-weight: bold;
    }
  }

  .award-wrapper {
    width: 88%;
    padding: 10px 0;
    line-height: 24px;
    font-size: 12px;
    text-align: center;
    background-image: linear-gradient(to right , #fbe8e9, #f7c7ca);
    .award {
      margin-bottom: 10px;
      font-size: 18px;
    }
    .award-detail {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;

      .copy-item {
        width: 88px;
      }
    }
    .award-label {
      width: 40px;
    }
    .award-content {
      width: 100px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .jump-image {
    width: 88%;
    margin-top: 15px;
    img {
      width: 100%;
    }
  }
}
</style>