<template>
  <div class="content">
    <Nav
      title="我的记录"
      :fixed="true"
    />
    <van-tabs v-model="active" @click="selectLecture">
      <van-tab title="全部">
        <van-empty  v-if="lectureList.length === 0" :image="noDataImg" description="暂无记录，快去讲座中心看看吧~">
          <van-button
            type="primary"
            size="large"
            @click.stop="toMainPage"
            color="#DB0011"
            >去预约</van-button
          >
        </van-empty>
        <van-list
          v-else
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="loadData"
        >
          <van-cell
            class="list-block"
            v-for="(item, index) in lectureList"
            :key="index"
            @click="toDetail(item)"
          >
            <div class="list-bg-block">
              <!-- <img :src="item.coverUrl" alt="" /> -->
              <van-image fit="cover" width="100%" height="100%" :src="item.coverUrl" />
              <!-- <div class="list-desc">
                <div class="flex-block">
                  <div class="doctor-name font17 font600">{{ item.lecturerName }}</div>
                  <div class="font13 font500">{{ item.lecturerTitle }}</div>
                </div>
                <div class="hospital font11 light-grey">
                  {{ item.hospital }}
                </div>
              </div> -->
            </div>
            <div class="flex-block lecture-title">
              <div class="title-width font16 font400">{{ item.name }}</div>
              <!-- div class="font12 price">
                {{ item.costType === 0 ? "免费" : "付费" }}
              </div -->
            </div>
            <div class="flex-block light-grey time-block">
              <div class="font13 lecture-time">讲师：</div>
              <div class="font13">{{ item.lecturerName }}</div>
            </div>
            <div class="flex-block light-grey time-block">
              <div class="font13 lecture-time">讲师头衔：</div>
              <div class="font13">{{ item.lecturerTitle }}</div>
            </div>
            <div class="flex-block light-grey time-block">
              <div class="font13 lecture-time">所属机构：</div>
              <div class="font13">{{ item.hospital }}</div>
            </div>
            <div v-if="item.playType !== 0" class="flex-block light-grey time-block">
              <van-icon name="clock-o" />
              <div class="font13 lecture-time">讲座时间：</div>
              <div class="font13">{{ item.startTime }}</div>
            </div>
            <div class="flex-block light-grey location-block">
              <van-icon name="location-o" />
              <div class="font13">{{ item.location }}</div>
            </div>
            <div class="appoint-btn" v-if="item.lineType === 1">
              <van-button
                type="primary"
                size="large"
                :color="item.btnTxt === '未开始' ? '#DB0011' : '#D7D8D6'"
                >{{ item.btnTxt }}</van-button
              >
            </div>
          </van-cell>
        </van-list>
      </van-tab>
      <van-tab title="线下讲座" v-if="false">
        <van-empty  v-if="lectureList.length === 0" :image="noDataImg" description="暂无记录，快去讲座中心看看吧~">
          <van-button
            type="primary"
            size="large"
            @click.stop="toMainPage"
            color="#DB0011"
            >去预约</van-button
          >
        </van-empty>
        <van-list
          v-else
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="loadData"
        >
          <van-cell
            class="list-block"
            v-for="(item, index) in lectureList"
            :key="index"
            @click="toDetail(item)"
          >
            <div class="list-bg-block">
              <!-- <img :src="item.coverUrl" alt="" /> -->
              <van-image fit="cover" width="100%" height="100%" :src="item.coverUrl" />
              <!-- <div class="list-desc">
                <div class="flex-block">
                  <div class="doctor-name font17 font600">{{ item.lecturerName }}</div>
                  <div class="font13 font500">{{ item.lecturerTitle }}</div>
                </div>
                <div class="hospital font11 light-grey">
                  {{ item.hospital }}
                </div>
              </div> -->
            </div>
            <div class="flex-block lecture-title">
              <div class="title-width font16 font400">{{ item.name }}</div>
              <div class="font12 price">
                {{ item.costType === 0 ? "免费" : "付费" }}
              </div>
            </div>
            <div class="flex-block light-grey time-block">
              <div class="font13 lecture-time">讲师：</div>
              <div class="font13">{{ item.lecturerName }}</div>
            </div>
            <div class="flex-block light-grey time-block">
              <div class="font13 lecture-time">讲师头衔：</div>
              <div class="font13">{{ item.lecturerTitle }}</div>
            </div>
            <div class="flex-block light-grey time-block">
              <div class="font13 lecture-time">所属机构：</div>
              <div class="font13">{{ item.hospital }}</div>
            </div>
            <div v-if="item.playType !== 0" class="flex-block light-grey time-block">
              <van-icon name="clock-o" />
              <div class="font13 lecture-time">讲座时间：</div>
              <div class="font13">{{ item.startTime }}</div>
            </div>
            <div class="flex-block light-grey location-block">
              <van-icon name="location-o" />
              <div class="font13">{{ item.location }}</div>
            </div>
            <div class="appoint-btn" v-if="item.lineType === 1">
              <van-button
                type="primary"
                size="large"
                :color="item.btnTxt === '未开始' ? '#DB0011' : '#D7D8D6'"
                >{{ item.btnTxt }}</van-button
              >
            </div>
          </van-cell>
        </van-list>
      </van-tab>
      <van-tab title="线上讲座" v-if="false">
        <van-empty  v-if="lectureList.length === 0" :image="noDataImg" description="暂无记录，快去讲座中心看看吧~">
          <van-button
            type="primary"
            size="large"
            @click.stop="toMainPage"
            color="#DB0011"
            >去预约</van-button
          >
        </van-empty>
        <van-list
          v-else
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="loadData"
        >
          <van-cell
            class="list-block"
            v-for="(item, index) in lectureList"
            :key="index"
            @click="toDetail(item)"
          >
            <div class="list-bg-block">
              <!-- <img :src="item.coverUrl" alt="" /> -->
              <van-image fit="cover" width="100%" height="100%" :src="item.coverUrl" />
              <!-- <div class="list-desc">
                <div class="flex-block">
                  <div class="doctor-name font17 font600">{{ item.lecturerName }}</div>
                  <div class="font13 font500">{{ item.lecturerTitle }}</div>
                </div>
                <div class="hospital font11 light-grey">
                  {{ item.hospital }}
                </div>
              </div> -->
            </div>
            <div class="flex-block lecture-title">
              <div class="title-width font16 font400">{{ item.name }}</div>
              <div class="font12 price">
                {{ item.costType === 0 ? "免费" : "付费" }}
              </div>
            </div>
            <div class="flex-block light-grey time-block">
              <div class="font13 lecture-time">讲师：</div>
              <div class="font13">{{ item.lecturerName }}</div>
            </div>
            <div class="flex-block light-grey time-block">
              <div class="font13 lecture-time">讲师头衔：</div>
              <div class="font13">{{ item.lecturerTitle }}</div>
            </div>
            <div class="flex-block light-grey time-block">
              <div class="font13 lecture-time">所属机构：</div>
              <div class="font13">{{ item.hospital }}</div>
            </div>
            <div v-if="item.playType !== 0" class="flex-block light-grey time-block">
              <van-icon name="clock-o" />
              <div class="font13 lecture-time">讲座时间：</div>
              <div class="font13">{{ item.startTime }}</div>
            </div>
            <div class="flex-block light-grey location-block">
              <van-icon name="location-o" />
              <div class="font13">{{ item.location }}</div>
            </div>
            <div class="appoint-btn" v-if="item.lineType === 1">
              <van-button
                type="primary"
                size="large"
                :color="item.btnTxt === '未开始' ? '#DB0011' : '#D7D8D6'"
                >{{ item.btnTxt }}</van-button
              >
            </div>
          </van-cell>
        </van-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import Nav from '@/components/VNavbar'
import { queryMyRecord } from "@/api/lecture";
export default {
  components: {
    Nav
  },
	data () {
    return {
      active: 0,
      loading: false,
      finished: false,
      curTime: null,
      lectureList: [],
      noDataImg: require('@/assets/images/empty.png'),
      page: {
        current: 1,
        size: 10,
        isAsc: false,
      },
    };
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.page.type = this.active
      queryMyRecord(this.page).then(res => {
        this.page.current++
        let recordArr = res.data.records
        let curTime = new Date(res.currentDate.replace(/-/g, '/')).getTime()
        recordArr.map(item => {
          let startTime = item.startTime ? new Date(item.startTime.replace(/-/g, '/')).getTime() : item.startTime
          let endTime = item.endTime ? new Date(item.endTime.replace(/-/g, '/')).getTime() : item.endTime
          item.coverUrl = item.coverUrl.includes('http') ? item.coverUrl : require("@/assets/images/no-data.png")
          item.location = item.lineType === 0 ? `线上/${item.playType === 0 ? '录播' : '直播'}` : `线下/${item.address}`
          item.btnTxt = startTime ? this.handleStatusBtn(curTime, startTime, endTime) : ''
          this.lectureList.push(item)
          this.loading = false;
        })
        if (this.lectureList.length === res.data.total) {
          this.finished = true;
        } else {
          this.finished = false;
        }
      })
    },
    handleStatusBtn(cur, start, end) {
      if (cur < start) {
        return '未开始'
      } else if (start <= cur && cur <= end) {
        return '进行中'
      } else {
        return '已结束'
      }
    },
    selectLecture() {
      this.lectureList = []
      this.page.current = 1
      if (this.active === 1) {
        this.page.lineType = 0
      } else if (this.active === 2) {
        this.page.lineType = 1
      } else {
        this.page = {
          current: 1,
          size: 2,
          isAsc: false,
        }
      }
      this.loadData()
    },
    toDetail(item) {
      this.$router.push({
        path: "/lecture/detail",
        query: {
          id: item.id,
          lectureDetailId: item.lectureDetailId
        }
      });
    },
    toMainPage() {
      this.$router.push("/lecture");
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .van-nav-bar--fixed {
  z-index: 999 !important;
}
/deep/ .van-nav-bar__text {
  color: #353535;
}
/deep/ .van-cell {
  padding: 0;
}
/deep/ .van-empty__bottom {
  width: 80%;
}
/deep/ .van-empty__image {
  width: 56px;
  height: 50px;
}
.content {
  background: #f7f7f7;

  .flex-block {
    display: flex;
    align-items: center;
  }

  .font17 {
    font-size: 17px;
  }

  .font16 {
    font-size: 16px;
  }

  .font13 {
    font-size: 13px;
  }

  .font12 {
    font-size: 12px;
  }

  .font11 {
    font-size: 11px;
  }

  .font600 {
    font-weight: 600;
  }

  .font500 {
    font-weight: 500;
  }

  .font400 {
    font-weight: 400;
  }

  .light-grey {
    color: #767676;
  }

  /deep/.van-tabs__line {
    width: 33.3333%;
    background-color: #db0011;
  }

  .list-block {
    width: calc(100% - 32px);
    margin: 16px;
    background: #ffffff;
    .list-bg-block {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 146px;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .list-desc {
        margin-left: 24px;
        z-index: 1;
      }
      .doctor-name {
        margin-right: 6px;
      }
      .hospital {
        margin-top: 6px;
      }
    }
    .lecture-title {
      margin: 14px 14px 10px 14px;
      align-items: center;
    }
    .title-width {
      width: calc(100% - 63px);
    }
    .lecture-time {
      min-width: 75px;
    }
    .price {
      padding: 2px 8px;
      margin-left: 17px;
      background: #ededed;
      color: #333333;
    }
    .time-block,
    .location-block {
      margin-left: 12px;
      padding-bottom: 8px;
    }
    .appoint-btn {
      padding: 8px 16px 18px 16px;
      /deep/ .van-button--large {
        height: 48px;
        font-size: 16px;
      }
    }
  }

  .popup-content {
    padding: 8px 18px 24px 18px;
  }

  .popup-tips {
    margin-top: 16px;
    color: #58595b;
  }
}
</style>
