<template>
  <svg :class="className" xmlns="http://www.w3.org/2000/svg">
    <title v-if="title">{{ title }}</title>
    <use :xlink:href="iconPath" xmlns:xlink="http://www.w3.org/1999/xlink" />
  </svg>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  export default defineComponent({
    name: 'VSvgIcon',

    props: {
      name: {
        type: String,
        required: true,
      },

      title: {
        type: String,
        default: null,
      },
    },

    computed: {
      iconPath() {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        // let icon = require(`@/assets/icons/${this.name}.svg`)
        // if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
        //   icon = icon.default
        // }
        // return `#${icon.id}`
        return ''
      },

      className() {
        return 'svg-icon svg-icon--' + this.name
      },
    },
  })
</script>

<style lang="less" scoped>
  .svg-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }
</style>
