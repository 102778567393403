import { render, staticRenderFns } from "./bbcInfoSearch.vue?vue&type=template&id=4601ee36&scoped=true&"
import script from "./bbcInfoSearch.vue?vue&type=script&lang=js&"
export * from "./bbcInfoSearch.vue?vue&type=script&lang=js&"
import style0 from "./bbcInfoSearch.vue?vue&type=style&index=0&id=4601ee36&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4601ee36",
  null
  
)

export default component.exports